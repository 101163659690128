$lightblue: #51c8ec;

.cmp-config {
    .pad {
        padding: 5px;
    }

    button {
        padding: 10px;
        margin-left: 10px;
        margin-bottom: 0px;
    }

    .padding {
        padding: 20px;
    }
    .flex {
        display: flex;
        flex-direction: row;
        &.right {
            justify-content: flex-end;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 0px 20px;
        grid-gap: 10px;
    }

    .grid-item {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: stretch;

        padding: 5px;
        margin-bottom: 5px;

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        .grid-item-inner {
            // outline: 1px solid   red;
            border: 1px solid #aaaaaa;
            border-radius: 5px;
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            padding: 5px;
            &.selected {
                background-color: $lightblue;
            }

            .grid-item-text {
                flex-grow: 1;
                text-align: left;
                font-size: 14px;
            }
        }
    }
    .grid-button {
        border: 1px solid #aaaaaa;
        border-radius: 5px;
        // margin-bottom: 10px;
        padding: 10px;
        margin-left: 5px;

        border-radius: 5px;
        font-size: 18px;
        display: flex;
    }

    .col {
        .flex {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            background-color: #eeeeee;
            padding: 10px;
            margin-bottom: 10px;

            // border-bottom: 1px solid #333333;

            .title {
                flex-grow: 1;
                text-align: left;
            }

            .add {
                // border: 1px solid #aaaaaa;
                border-radius: 5px;
                // margin-bottom: 10px;
                padding: 5px;
                font-size: 18px;
                display: flex;
            }
        }

        .grid-item:nth-child(even) {
            // background-color: #eeeeee;
        }
    }
    .json {
        grid-column-start: span 4;

        margin-bottom: 20px;

        .btns-match {
            justify-content: start;
        }
        
        .btn {
            border-radius: 2px;
            border: 1px solid #aaaaaa;
            font-weight: 700;

            &.selected {
                background-color: $lightblue;
            }
        }

        textarea {
            width: 100%;
            min-height: 300px;
        }

        .disabled {
            textarea {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
}
