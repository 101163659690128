$scorePass: #9dbe3a;
$scorePartial: #fec62b;
$scoreNone: #cf2727;

.match-rule {
    padding: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
    @media only screen and (max-width: 1200px) {
        font-size: 12px;
    }

    &.full {
        background: linear-gradient(to right, $scorePass 50%, white 50%);
        background-size: 200% 100%;
        background-position: left bottom;
    }

    &.partial {
        background: linear-gradient(to right, $scorePartial 50%, white 50%);
        background-size: 200% 100%;
        background-position: left bottom;
    }

    &.none {
        background: linear-gradient(to right, $scoreNone 50%, white 50%);
        background-size: 200% 100%;
        background-position: left bottom;
    }

    &.skip {
        background: linear-gradient(to right, #aaaaaa 50%, white 50%);
        background-size: 200% 100%;
        background-position: left bottom;
    }

    .match-name {
        font-size: 14px;
        font-weight: 700;
    }

    .match-rule {
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
    }
}
