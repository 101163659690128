$darkblue: #0d1428;
$lightblue: #50c7eb;
$grey1: #f3fafd;
$grey2: #a2b4bf;
$grey3: #6d7e88;
$white: #ffffff;

$blue: #115098;

.result-icon {
    $scorePass: #9dbe3a;
    $scorePartial: #fec62b;
    $scoreNone: #cf2727;

    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    margin-right: 10px;

    .inner {
        border: 10px solid #9dbe3a;
        color: #9dbe3a;
        border-radius: 100px;

        font-size: 40px;
        padding: 10px;
    }
    &.pass {
        .inner {
            border-color: $scorePass;
            color: $scorePass;
        }
    }
    &.partial {
        .inner {
            border-color: $scorePartial;
            color: $scorePartial;
        }
    }
    &.none {
        .inner {
            border-color: $scoreNone;
            color: $scoreNone;
        }
    }

    .inner-text {
        background-color: #9dbe3a;
        border: 10px solid #9dbe3a;
        border-radius: 10px 100px 100px 10px;
        margin-left: -10px;
        margin-top: 20px;
        margin-bottom: 20px;

        color: $white;
        font-size: 16px;

        width: 100px;
        text-align: center;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &.pass {
        .inner-text {
            background-color: $scorePass;
            border-color: $scorePass;
        }
    }
    &.partial {
        .inner-text {
            background-color: $scorePartial;
            border-color: $scorePartial;
        }
    }
    &.none {
        .inner-text {
            background-color: $scoreNone;
            border-color: $scoreNone;
        }
    }

    @media only screen and (max-width: 900px) {
        .inner {
            border-width: 5px;
            border-radius: 100px;
            font-size: 20px;
            padding: 5px;
        }
        .inner-text {
            font-size: 12px;
            // width: 80px;
            margin-top: 8px;
            margin-bottom: 8px;
            border-width: 5px;
            margin-left: -5px;
        }
    }
}
