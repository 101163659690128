$darkblue: #0d1428;
$lightblue: #50c7eb;
$grey1: #f3fafd;
$grey2: #a2b4bf;
$grey3: #6d7e88;
$white: #ffffff;

$blue: #115098;

.route-home {
    .page {
        // background-color: #eeeeee;
        padding: 20px;
        overflow-x: hidden;

        .page-content {
            .content {
                display: flex;
                flex-direction: column;
                background-color: #ffffff;
                padding: 10px;
            }

            .buttons {
                // outline: 1px solid green;

                display: flex;
                flex-direction: row;
                justify-content: center;

                padding: 20px;
            }

            a {
                text-decoration: none;
                color: #0d1428;
            }

            .button {
                border: 1px solid #AAAAAA;
                border-radius: 3px;
                padding: 20px;

                width: 200px;
                height: 200px;
                text-align: center;
                margin-left: 10px;
                margin-right: 10px;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                
                font-size: 30px;
                
                &:hover {
                    background-color: #EFEFEF;
                    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);

                }
            }
        }
    }
}
