$scorePass: #9dbe3a;
$scorePartial: #fec62b;
$scoreNone: #cf2727;

.kyc-waterfall {
    .flex-vertical {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .flex-grow {
        flex-grow: 1;
    }

    .group-result {
        min-height: 200px;
    }

    .waterfall-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 5px;
        transition: all 1s linear;
        background: linear-gradient(to right, white 50%, white 50%);
        background-size: 200% 100%;
        background-position: right bottom;
        font-size: 14px;
        margin-bottom: 1px;
        @media only screen and (max-width: 1200px) {
            font-size: 12px;
        }

        &.active {
            background: linear-gradient(to right, red 50%, white 50%);
            background-size: 200% 100%;
            background-position: left bottom;
            &.full {
                background: linear-gradient(to right, $scorePass 50%, white 50%);
                background-size: 200% 100%;
                background-position: left bottom;
            }

            &.partial {
                background: linear-gradient(to right, $scorePartial 50%, white 50%);
                background-size: 200% 100%;
                background-position: left bottom;
            }

            &.none {
                background: linear-gradient(to right, $scoreNone 50%, white 50%);
                background-size: 200% 100%;
                background-position: left bottom;
            }

            &.skip {
                background: linear-gradient(to right, #aaaaaa 50%, white 50%);
                background-size: 200% 100%;
                background-position: left bottom;
            }
        }

        .match-text {
            color: #ffffff;

            min-width: 120px;
            text-align: right;
        }

        .waterfall-bubble {
            width: 100%;
            background-color: #ffffff;
            border-radius: 3px;
            border: 1px solid #333333;
            padding: 5px;
            margin-bottom: 0px;
        }

        h3 {
            margin: 0px 10px;
        }
    }
}
