$darkblue: #0d1428;
$lightblue: #50c7eb;
$grey1: #f3fafd;
$grey2: #a2b4bf;
$grey3: #6d7e88;
$white: #ffffff;

$blue: #115098;

.route-kyc {
    .page {
        background-color: #eeeeee;
        padding: 20px;
        overflow-x: hidden;

        .page-title {
            background-color: #ffffff;
            text-align: center;
            padding: 10px;
            margin-bottom: 20px;
            h3 {
                margin: 0px;
            }
        }

        .page-content {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 20px;

            .content {
                display: flex;
                flex-direction: column;
                background-color: #ffffff;
                padding: 10px;
            }

            .content-worldview {
                grid-column-start: span 2;
                padding: 0px;
            }

            .content-json {
                grid-column-start: span 4;

                .text-json {
                    height: 1000px;
                }
            }
            .content-result {
                grid-column-start: span 4;

                .flex-parent {
                    @media only screen and (max-width: 900px) {
                        flex-direction: column;
                    }
                }

                .description {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
                .score-json {
                    width: 300px;
                    @media only screen and (max-width: 900px) {
                        width: 100%;
                    }
                }
            }

            @media only screen and (max-width: 900px) {
                grid-template-columns: 1fr;

                .content-worldview {
                    grid-column-start: span 1;
                }

                .content-json {
                    grid-column-start: span 1;
                }
                .content-result {
                    grid-column-start: span 1;
                }
            }
        }
    }
}