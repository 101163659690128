body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
  .flex {
    display: flex;

    *.grow {
      flex-grow: 1;
    }
    &.grow {
      flex-grow: 1;
    }
    &.row {
      flex-direction: row;
    }
    &.column {
      flex-direction: column;
    }
    &.overflow {
      flex-wrap: wrap;
    }
    &.start {
      align-items: start;
    }
    &.center {
      align-items: center;
    }
  }

  .group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    text-align: left;

    &.center {
      text-align: center;
    }
    label {
      display: block;
    }
    h3 {
      margin: 0px;
      margin-bottom: 5px;
    }
    textarea {
      min-height: 200px;
    }
    select {
      height: 30px;
    }
    button {
      height: 30px;
    }
  }
}
