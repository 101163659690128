.kyc-form {
    height: 100%;
    .button {
        &.disabled {
            opacity: 0.5;
        }
    }
    .group {
        &.disabled {
            opacity: 0.5;
        }
        &.button {
            margin: 0px;
        }
        &.flex {
            flex-grow: 1;
            textarea {
                flex-grow: 1;
            }
        }
    }
}
