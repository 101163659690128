.route-login {
    padding-top: 100px;

    .error {
        color: red;
    }

    .form {
        background-color: #ffffff;
        border: 1px solid #dddddd;
        max-width: 300px;
        margin: 0 auto;
        padding: 20px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24), 0 2px 10px 0 rgba(0, 0, 0, 0.19);

        .form-group {
            width: 100%;
            margin-bottom: 10px;
            label {
                display: block;
                text-align: left;
                font-weight: 700;
                margin-bottom: 5px;
            }
            input {
                width: 100%;
                height: 30px;
            }
        }
        button {
            color: #333333;
            padding: 12px 20px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            font-weight: 700;
            border: 1px solid #333333;
            border-radius: 3px;
        }

        .forgot {
            font-size: 14px;
        }
        .divider {
            width: 100%;
            border-top: 2px solid #eeeeee;
            margin-top: 10px;
            margin-bottom: 10px;
            height: 1px;
        }
    }
}
