.cmp-nav {
    display: flex;
    border-bottom: 1px solid #333333;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    min-height: 1rem;
    .flex {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        align-items: stretch;
    }
    .grow {
        flex-grow: 1;
    }
    .logo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-width: 90px;
        width: 90px;
        height: 100%;
        border-right: 1px solid rgba(129, 139, 150, 0.15);
        padding: 0px 10px;
    }
    .nav-item {
        color: #126b87;
        -webkit-user-select: text;
        user-select: text;
        line-height: 1.3;
        padding: 13px 16px;
        border-right: 1px solid rgba(129, 139, 150, 0.15);
        a {
            text-decoration: none;
            color: #126b87;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.3;
        }
        &:last-child {
            border-right: 1px solid rgba(34, 36, 38, 0.15);
        }
    }
    .logout {
        color: #126b87;
        -webkit-user-select: text;
        user-select: text;
        line-height: 1.3;
        padding: 13px 16px;
        a {
            text-decoration: none;
            color: #126b87;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.3;
        }
    }
    .login {
        color: #126b87;
        -webkit-user-select: text;
        user-select: text;
        line-height: 1.3;
        padding: 0px 20px;
        a {
            text-decoration: none;
            color: #126b87;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.3;
        }
    }

    @media only screen and (max-width: 900px) {
        .logo {
            min-width: 50px !important;
            width: 50px;
            padding: 0px;
            img {
                width: 100%;
                height: auto;
            }
        }
        .nav-item {
            padding: 8px 8px;
        }

        label, a {
            font-size: 12px !important;
        }
    }
}
